import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App)

import req from '@/utils/req' // req
// set req
app.config.globalProperties.$req = req

import '@/styles/base.scss' // 全局样式

// 引入 ElementPlus 插件(npm i element-plus)
import ElementPlusPlugin from '@/plugins/element-plus'

// 引入基本模板
import * as echarts from 'echarts'
app.config.globalProperties.$echarts = echarts

// 全局注册 ElementPlus 图标组件(npm install @element-plus/icons-vue)
import * as ElementPlusIcons from '@element-plus/icons-vue'
for(const [key, component] of Object.entries(ElementPlusIcons)) {
  app.component(key, component)
}

import '@/auth' // 权限

app
.use(store)
.use(router)
.use(ElementPlusPlugin)
.mount('#app')